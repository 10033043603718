<script>
import { debounce } from '@/utils/index'
import { calculation } from '@/api/home'
import { Toast } from 'vant'
import { validatePhone } from '@/utils/validator'
import MessageBox from './MessageBox.vue'
import { vertical } from '@/utils/options'
export default {
  name: 'footer-page',
  props: {},
  components: { MessageBox },
  data () {
    return {
      show: false,
      showArea: false,
      value: '',
      options: vertical,
      validatePhone,
      formData: {
        name: '',
        fansNumber: '',
        verticalCategory: undefined,
        mobile: '',
        area: undefined
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    test () {
      this.show = true
    },
    onFinish ({ selectedOptions }) {
      this.showArea = false
      this.formData.area = selectedOptions.map(option => option.text).join('/')
    },
    submit () {
      this.$refs.formRef.validate().then(() => {
        calculation(this.formData).then(() => {
          this.show = false
          this.$refs.messageBoxRef.open()
          this.formData = {
            name: '',
            fansNumber: '',
            verticalCategory: undefined,
            mobile: '',
            area: undefined
          }
        }).catch(error => error)
      }).catch(() => Toast({ message: '以上信息填写完整后才能测算哦~', position: 'top' }))
    },

    debounceSubmit: debounce(function () {
      this.submit()
    }, 500),
    onChangePhone (e) {
      const val = e.target.value
      const reg = /^1[3456789]\d{9}$/
      if (!reg.test(val)) return Toast('手机号码格式不正确')
    }
  },
  created () {
  },
  mounted () {},
  beforeDestroy () {}
}
</script>
<template>
  <div>
    <div class="footer flx-center">
      <img
        src="@/assets/home/testBtn@2x.png"
        alt=""
        class="footer_btn"
        @click="test"
      />
    </div>

    <van-popup
      v-model="show"
      closeable
      round
      position="bottom"
      :style="{
        height: '380px',
        maxWidth: '750px',
        color: '#000000',
        background:
          'linear-gradient( 180deg, rgba(246,121,130,0.79) -5%, rgba(246,121,130,0) 15%), #FFFFFF',
      }"
    >
      <template #default>
        <div class="popup">
          <div class="title flx-center fs20" style="height: 60px; color: #313a45">
            免费测算
          </div>

          <van-form ref="formRef">
            <van-field
              label="账号名称"
              placeholder="请输入预测账号的完整名称"
              maxlength="200"
              clickable
              :rules="[{ required: true }]"
              v-model="formData.name"
            />
            <van-field
              label="账号粉丝数"
              placeholder="精确到个位数，如：2360000"
              type="number"
              clickable
              :rules="[{ required: true }]"
              v-model="formData.fansNumber"
            />
            <van-field
              readonly
              clickable
              name="picker"
              label="账号领域"
              placeholder="请选择账号领域"
              right-icon="arrow"
              @click="showArea = true"
              :rules="[{ required: true }]"
              v-model="formData.area"
            />
            <van-popup v-model="showArea" position="bottom">
              <van-cascader
                v-model="formData.verticalCategory"
                title="请选择账号领域"
                :options="options"
                @close="showArea = false"
                @finish="onFinish"
                />
            </van-popup>

            <van-field
              label="联系方式"
              placeholder="请输入手机号以接收预测报告"
              type="number"
              maxlength="11"
              :rules="[{ required: true, validatePhone }]"
              v-model="formData.mobile"
              @change="onChangePhone"
            />
            <img src="@/assets/home/submit@2x.png" alt="" @click="debounceSubmit" class="submit"/>
          </van-form>
        </div>
      </template>
    </van-popup>

    <MessageBox ref="messageBoxRef" />
  </div>
</template>
<style scoped lang="less">
.footer {
  height: 100px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  &_btn {
    cursor: pointer;
    width: 337px;
    height: 61px;
  }
}

.popup {
    width: 100%;
    font-family: PingFangSC;
    .title{
      font-family: Alibaba-PuHuiTiB;
    }
    .submit {
      display: flex;
      margin: 16px auto;
      width: 337px;
      height: 61px;
    }
  }

/deep/ .van-popup__close-icon {
  color: #3a3535;
}

/deep/ .van-cell:after {
  position: relative;
}

/deep/ .van-popup--bottom{
  left: 50%;
  transform: translateX(-50%);
}

/deep/ .van-cell{
  padding: 15px 16px;
  font-size: 16px;
}

/deep/ .van-field__label{
  color: #313A45;
}

/deep/ .van-field__control{
  font-family: PingFangSC;
}
</style>
