// 引入axios
import axios from 'axios'
// 创建axios实例

const serviceUrlPro = 'https://clue.xiaowutube.com/api'
const serviceUrlDev = 'http://172.16.25.32:10015'

const envServiceUrlMap = {
  development: serviceUrlDev,
  production: serviceUrlPro
}

const request = axios.create({
  // 基地址
  baseURL: envServiceUrlMap[process.env.NODE_ENV]
  // timeout: 10000
})

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么

    return response.data
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    // console.dir(error)
    // if (error.response) {
    //   if (error.response.status === 401) {
    //     router.push('/login')
    //   } else {
    //     Toast.fail(error.response.data.message)
    //   }
    // }
    return Promise.reject(error)
  }
)

export default request
