export const vertical = [
  {
    text: '美食',
    value: 'deliciousFood',
    id: 1,
    parentId: 0,
    children: [
      { text: '吃播', value: 'mukbang', id: 101, parentId: 1 },
      { text: '搞笑剧情吃播', value: 'funnyPlotMukbang', id: 102, parentId: 1 },
      { text: '三农美食', value: 'threeRuralCuisine', id: 103, parentId: 1 },
      { text: '美食制作', value: 'foodMaking', id: 104, parentId: 1 },
      { text: '美食测评', value: 'foodEvaluation', id: 105, parentId: 1 }
    ]
  },
  {
    text: 'Vlog',
    value: 'vlog',
    id: 2,
    parentId: 0,
    children: [
      { text: '日常生活', value: 'dailyLife', id: 201, parentId: 2 },
      { text: '亲子', value: 'parenting', id: 202, parentId: 2 },
      { text: '旅行', value: 'travel', id: 203, parentId: 2 },
      { text: '户外探索', value: 'outdoorExploration', id: 204, parentId: 2 },
      { text: '钓鱼/赶海', value: 'fishing', id: 205, parentId: 2 },
      { text: '街头采访', value: 'streetInterviewing', id: 206, parentId: 2 },
      { text: '挑战', value: 'challenge', id: 207, parentId: 2 },
      { text: '家庭娱乐', value: 'homeEntertainment', id: 208, parentId: 2 },
      { text: '萌宠', value: 'cutePet', id: 209, parentId: 2 },
      { text: '文玩', value: 'wenwan', id: 210, parentId: 2 },
      { text: '三农', value: 'threeNong', id: 211, parentId: 2 }
    ]
  },
  {
    text: '游戏',
    value: 'game',
    id: 3,
    parentId: 0,
    children: [
      { text: '桌游棋牌', value: 'cardGames', id: 301, parentId: 3 },
      { text: '游戏解说', value: 'gameCommentary', id: 302, parentId: 3 }
    ]
  },
  {
    text: '影视剧',
    value: 'movie',
    id: 4,
    parentId: 0,
    children: [
      { text: '网络微短剧', value: 'onlineMicroDrama', id: 401, parentId: 4 },
      { text: '达人IP短剧', value: 'expertIPShortDrama', id: 402, parentId: 4 },
      { text: '电影/电视剧', value: 'movies/TV', id: 403, parentId: 4 },
      { text: '动漫', value: 'comic', id: 404, parentId: 4 },
      { text: '纪录片', value: 'documentary', id: 405, parentId: 4 },
      { text: '影视剧切条', value: 'TVslitting', id: 406, parentId: 4 },
      { text: '搞笑短剧', value: 'funnySkit', id: 407, parentId: 4 },
      { text: '自制动画', value: 'homemadeAnimation', id: 408, parentId: 4 }
    ]
  },
  {
    text: '图文视讯',
    value: 'videotex',
    id: 5,
    parentId: 0,
    children: [
      { text: '社会新闻', value: 'socialNews', id: 501, parentId: 5 },
      { text: '时政军事', value: 'currentPoliticsMilitary', id: 502, parentId: 5 },
      { text: '财经', value: 'financeAndEconomics', id: 503, parentId: 5 },
      { text: '影视解说', value: 'filmNarrate', id: 504, parentId: 5 },
      { text: '科普', value: 'popularizationOfScience', id: 505, parentId: 5 },
      { text: '体育资讯', value: 'sportsNews', id: 506, parentId: 5 },
      { text: '体育赛事解说', value: 'sportsCommentary', id: 507, parentId: 5 },
      { text: '娱乐', value: 'entertainment', id: 508, parentId: 5 },
      { text: '猎奇', value: 'seekNovelty', id: 509, parentId: 5 },
      { text: '命理', value: 'numerology', id: 510, parentId: 5 },
      { text: '小说推文', value: 'novelTweets', id: 511, parentId: 5 },
      { text: '人文历史', value: 'humanHistory', id: 512, parentId: 5 },
      { text: '国学', value: 'sinology', id: 513, parentId: 5 },
      { text: '动物', value: 'animal', id: 514, parentId: 5 },
      { text: '漫画解说', value: 'comicCommentary', id: 515, parentId: 5 }
    ]
  },
  {
    text: '创新',
    value: 'innovate',
    id: 6,
    parentId: 0,
    children: [
      { text: '特效', value: 'VFX', id: 601, parentId: 6 },
      { text: '解压', value: 'decompression', id: 602, parentId: 6 },
      { text: 'DIY手工', value: 'diy', id: 603, parentId: 6 }
    ]
  },
  {
    text: '知识',
    value: 'knowledge',
    id: 7,
    parentId: 0,
    children: [
      { text: '汽车', value: 'automobile', id: 701, parentId: 7 },
      { text: '星座/情感', value: 'constellation', id: 702, parentId: 7 },
      { text: '数码测评/开箱测评', value: 'digitalEvaluation', id: 703, parentId: 7 },
      { text: '养生/健身/武术', value: 'fitness', id: 704, parentId: 7 },
      { text: '时尚潮流', value: 'fashionTrends', id: 705, parentId: 7 },
      { text: '音乐/舞蹈', value: 'music/dance', id: 706, parentId: 7 },
      { text: '体育教学', value: 'physicalEducation ', id: 707, parentId: 7 },
      { text: '知识分享', value: 'knowledgeSharing', id: 708, parentId: 7 },
      { text: '课程', value: 'course', id: 709, parentId: 7 }
    ]
  },
  {
    text: '儿童',
    value: 'children',
    id: 8,
    parentId: 0,
    children: [
      { text: '少儿娱乐', value: 'childrenEntertainment', id: 801, parentId: 8 },
      { text: '早教启蒙', value: 'earlyEducation', id: 802, parentId: 8 },
      { text: '动画片', value: 'cartoon', id: 803, parentId: 8 },
      { text: '玩具手作', value: 'toyCraft', id: 804, parentId: 8 }
    ]
  },
  {
    text: '其他',
    value: 'other',
    id: 9,
    parentId: 0,
    children: [
      { text: '其他', value: 'other', id: 901, parentId: 9 }
    ]
  }
]
