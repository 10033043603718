<script>
export default {
  name: 'ForecastCard',
  props: {},
  components: {},
  data () {
    return {
      items: [
        { label: '大数据支撑：', value: '分析100,000+成功出海案例' },
        { label: 'AI智能匹配：', value: ' 精准预测您的海外收益潜力' },
        { label: '专业背书：', value: '千万级账号操盘手倾情推荐' }
      ]
    }
  },
  computed: {},
  watch: {},
  methods: {

  },
  created () {},
  mounted () {

  },
  beforeDestroy () {

  }
}
</script>
<template>
  <div class="platform">
    <div class="platform_card">
      <div class="platform_card_title fs16">
        官方合作平台 · 专业值得信赖
      </div>
      <div class="platform_card_content">
        <div class="platform_card_content_trophy">
          <img src="@/assets/home/trophy@2x.png" alt="">
        </div>
        <div class="platform_card_content_list">
          <div class="platform_card_content_list_item" v-for="item,index in items" :key="index">
          <img src="@/assets/home/arrow@2x.png" alt="">
          <span class="label">{{ item.label }}</span>
          <span>{{ item.value }}</span>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less">
.platform {
  background-color: var(--background-color);
  padding: 24px 20px;
  &_card {
    overflow: hidden;
    background-image: url('@/assets/home/box2@2x.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding: 6px 14px;
    &_title {
      text-align: center;
    }
    &_content {
      padding: 30px 10px 15px 10px;
      overflow: hidden;
      &_trophy{
        img{
          width: 100%;
        }
      }
      &_list {
        margin-top: 12px;
        &_item {
          padding: 5px 0px;
          display: flex;
          align-items: center;
          font-family: AlibabaPuHuiTiM;
          font-size: 14px;
          img{
            width: 8px;
            height: 10px;
            margin-right: 8px;
          }
          .label{
            color: #FBCB16;
          }
        }
      }
    }
  }
}
</style>
