/**
 * 数字千分位
 * @param number
 * @return {string}
 */
export function toThousands (number) {
  const parts = number.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return parts.join('.')
}

/**
 * 用户名脱敏
 */
export function maskUserName (userName) {
  return userName.replace(/^(.)(.*?)(.)$/, '$1****$3')
}

// debounce.js
export const debounce = (fn, delay) => {
  let timeout = null // 保存定时器标识
  return function (...args) {
    // 每次触发时清除上一个定时器
    if (timeout) clearTimeout(timeout)

    // 设置新的定时器
    timeout = setTimeout(() => {
      fn.apply(this, args) // 使用 apply 保持上下文（this）和传递参数
    }, delay)
  }
}
