<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style scoped lang="less">
.app{
  margin: 0 auto;
  width: 100%;
  max-width: 750px;
  min-width: 375px;
  background-color: #fff;
}
</style>
