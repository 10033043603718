import Vue from 'vue'
import {
  Button,
  Tabbar,
  TabbarItem,
  Swipe,
  SwipeItem,
  NavBar,
  Toast,
  Form,
  Field,
  Icon,
  Popup,
  Loading,
  Row,
  Col,
  Picker,
  Area,
  Cascader
} from 'vant'

Vue.use(Loading)
Vue.use(Popup)
Vue.use(Form)
Vue.use(Field)
Vue.use(Icon)
Vue.use(Form)
Vue.use(Field)
Vue.use(Toast)
Vue.use(NavBar)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(Picker)
Vue.use(Area)
Vue.use(Cascader)
