<script>
import Footer from '@/layout/components/Footer.vue'
import PlatformCard from './PlatformCard.vue'
import ForecastCard from './ForecastCard.vue'
export default {
  name: 'home-page',
  props: {},
  components: { ForecastCard, PlatformCard, Footer },
  data () {
    return {
      show: false,
      showPicker: false,
      value: '',
      columns: ['杭州', '宁波', '温州', '嘉兴', '湖州']
    }
  },
  computed: {},
  watch: {},
  methods: {
    test () {
      this.show = true
    },
    onConfirm (value) {
      this.value = value
      this.showPicker = false
    },
    submit () {
      this.$router.push('/detail')
    }
  },
  created () {},
  mounted () {},
  beforeDestroy () {}
}
</script>
<template>
  <div class="home">
    <div class="home_banner">
      <div class="home_banner_img1">
        <img src="@/assets/home/banner@2x.png" alt=""/>
      </div>
      <div class="home_banner_img2">
        <img src="@/assets/home/ornament@2x.png" alt="" class="stat" />
        <img src="@/assets/home/title@2x.png" alt="" class="text" />
        <img src="@/assets/home/ornament@2x.png" alt="" class="stat" />
      </div>
    </div>
    <div class="home_content">
      <ForecastCard />
      <PlatformCard />
    </div>

    <div class="home_chance flx-center">
      <img src="@/assets/home/chance@2x.png" alt="" />
    </div>

    <Footer />
  </div>
</template>
<style scoped lang="less">
.home {
  width: 100%;
  &_banner {
    width: 100%;
    background: var(--background-color);
    position: relative;
    &_img1 {
      width: 100%;
      img {
        width: 100%;
        flex-shrink: 0;
      }
    }
    &_img2{
      position: absolute;
      bottom: 24px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0 12px;
      .text {
      flex-shrink: 0;
      width: 243px;
      height: 19px;
    }
    .stat {
      width: 22px;
      height: 33px;
    }
    }
  }
  &_chance {
    background: var(--background-color);
    padding-bottom: 130px;
    img {
      width: 287px;
      height: 71px;
    }
  }
}
</style>
