// 校验手机号
export const validatePhone = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('手机号不能为空'))
  }
  const reg = /^1[3456789]\d{9}$/
  if (reg.test(value)) {
    callback()
  } else {
    return callback(new Error('请输入正确的手机号'))
  }
}
