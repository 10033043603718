import request from '@/utils/request'

// 提交测试
export const calculation = function (params) {
  return request.get('/appApi/calculationLog/calculation', { params })
}

// 获取详情页
export const getResultByMobile = function (params) {
  return request.get('/appApi/calculationLog/getResultByMobile', {
    params
  })
}
