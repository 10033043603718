<script>
import { toThousands, maskUserName } from '@/utils'
export default {
  name: 'ForecastCard',
  props: {},
  components: {},
  data () {
    return {
      intervalId: null, // 存储定时器ID
      toThousands,
      maskUserName,
      data: [
        { accountName: '小拉泽频道', amount: '1412325' },
        { accountName: '沉浸式野外露营', amount: '523443' },
        { accountName: '荒野生存宗师', amount: '330089' },
        { accountName: '电影迷小雅', amount: '203124' },
        { accountName: '据说放映', amount: '423280' },
        { accountName: '搞事明天玩什么', amount: '224231' },
        { accountName: '阿斗归来了', amount: '152348' },
        { accountName: '大虾试车真香', amount: '513426' },
        { accountName: '杜克环游世界', amount: '184290' },
        { accountName: '李小冷不冷', amount: '622189' },
        { accountName: '冲哥说电影', amount: '105277' },
        { accountName: '六里屯之光', amount: '52349' },
        { accountName: '绵羊料理', amount: '142673' },
        { accountName: '钟文泽', amount: '623422' },
        { accountName: '摸鱼事务所', amount: '325232' },
        { accountName: '铁柱大导演', amount: '245234' },
        { accountName: '宇哥到处跑', amount: '1023336' },
        { accountName: '陈老师很挑食', amount: '73423' },
        { accountName: '蒜蒜蒜了八', amount: '245233' },
        { accountName: 'Grandpagu顾爷', amount: '653578' },
        { accountName: '一支小皮皮', amount: '54674' },
        { accountName: '兔米亚', amount: '74432' },
        { accountName: '成员外', amount: '98653' },
        { accountName: '电动车公社', amount: '77464' },
        { accountName: '刘老师说电影', amount: '36454' },
        { accountName: '虾堡的游戏人生', amount: '108678' },
        { accountName: '世界美食欧', amount: '74563' },
        { accountName: '水果猎人', amount: '66435' },
        { accountName: '在下哲别', amount: '4564' },
        { accountName: '任评测', amount: '86190' }
      ]
    }
  },
  computed: {},
  watch: {},
  methods: {
    getData () {
      // 需要控制数据为偶数
    },
    // 开始滚动
    startAutoScroll () {
      this.intervalId = setInterval(() => {
        if (this.$refs.scrollViewRef.scrollTop < this.$refs.listRef[0].clientHeight) {
          this.$refs.scrollViewRef.scrollTop += 1
        } else {
          this.$refs.scrollViewRef.scrollTop = 0
        }
      }, 50)
    },
    // 停止滚动
    stopAutoScroll () {
      if (this.intervalId) {
        clearInterval(this.intervalId)
        this.intervalId = null
      }
    },
    // 鼠标移入
    onmouseover () {
      this.stopAutoScroll() // 组件销毁前停止自动滚动
    },
    // 鼠标移出
    onmouseout () {
      this.startAutoScroll() // 组件挂载后开始自动滚动
    }

  },
  async created () {
  },
  mounted () {
    this.startAutoScroll() // 组件挂载后开始自动滚动
  },
  beforeDestroy () {
    this.stopAutoScroll() // 组件销毁前停止自动滚动
  }
}
</script>
<template>
  <div class="forecast">
    <div class="forecast_card">
      <div class="forecast_card_title fs16">
        已有 <span class="primaryC">1,523</span> 位创作者获得专属预测
      </div>
      <div class="forecast_card_mark flx-center gap3">
        <img
          src="@/assets/home/star@2x.png"
          alt=""
          v-for="(item, index) in 5"
          :key="index"
        />
        <span class="ml9">4.9分 (1,326条评价)</span>
      </div>
      <div class="forecast_card_content">
        <div
          ref="scrollViewRef"
          class="forecast_card_content_list"
          @mouseover="onmouseover"
          @mouseout="onmouseout"
        >
          <div v-for="(p, n) in 2" :key="n" ref="listRef">
            <div
              :class="['forecast_card_content_list_item']"
              v-for="(item, index) in data"
              :key="index"
            >
              <div>{{ maskUserName(item.accountName) }}</div>
              <div>¥ {{ toThousands(item.amount) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less">
.forecast {
  background-color: var(--background-color);
  padding: 0px 20px 24px 20px;
  &_card {
    // height: 335px;
    overflow: hidden;
    background-image: url('@/assets/home/box1@2x.png');
    background-size: 100% 100%;
    padding: 6px 14px;
    // margin-top: 24px;
    &_title {
      text-align: center;
    }
    &_mark {
        padding: 18px 0 0 0;
        font-size: 12px;
        color: #d9edff;
        img {
          width: 14px;
          height: 13px;
        }
      }
    &_content {
      padding: 15px 0;
      overflow: hidden;
      height: 270px;

      &_list {
        overflow-y: auto;
        height: 100%;

        &::-webkit-scrollbar {
          display: none;
        }

        &_item {
          padding: 9px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:nth-child(even){
            background: #4546c6;
          }
        }
      }
    }
  }
}
</style>
