<template>
  <div class="layout-page">
    <div class="layout-content">
      <keep-alive>
        <router-view style="height: 100%;"></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  name: 'layout-page',
  components: { }
}
</script>

<style lang="less" scoped>
.layout-page{
  width: 100%;
  height: 100%;
}
.layout-content {
  height: 100%;
}
</style>
