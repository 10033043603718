<script>
export default {
  name: 'MessageBox',
  props: {
    title: {
      type: String,
      default: '信息提交成功'
    },
    content: {
      type: String,
      default: '【测算报告】稍后将以短信的方式发送至您填写的手机号，请注意查收'
    }
  },
  components: {},
  data () {
    return {
      show: false
    }
  },
  computed: {},
  watch: {},
  methods: {
    open () {
      this.show = true
    },
    hide () {
      this.show = false
    }
  },
  created () {},
  mounted () {},
  beforeDestroy () {}
}
</script>
<template>
   <van-popup
      v-model="show"
      closeable
      round
      style="width: 279px; height: 260px"
    >
      <template #default>
        <div class="box">
          <img src="@/assets/home/success@2x.png" alt="" class="icon" />
          <div class="title fw600 fz18">{{ title }}</div>
          <div class="content">{{ content }}</div>
          <div class="btn" @click="hide">我知道了</div>
        </div>
      </template>
    </van-popup>
</template>
<style scoped lang="less">
.box {
  padding: 20px 20px;
  text-align: center;
  font-family: PingFangSC;
  .icon {
    width: 50px;
    height: 50px;
  }
  .title {
    color: #363a44;
    margin-top: 16px;
  }
  .content {
    font-weight: 400;
    font-size: 13px;
    color: rgba(49, 58, 69, 0.6);
    line-height: 20px;
    margin-top: 8px;
  }
  .btn {
    margin-top: 20px;
    padding: 10px;
    background: linear-gradient(45deg, #e85180 0%, #f37c8c 100%);
    box-shadow: inset 2px 4px 3px 0px rgba(255, 255, 255, 0.28),
      inset -2px -4px 3px 0px rgba(91, 0, 0, 0.12);
    border-radius: 8px;
    font-size: 14px;

  }
}
</style>
