import Layout from '@/layout/index.vue'
import Home from '@/views/pages/home/home.vue'
import Detail from '@/views/pages/detail/detail.vue'

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      { path: '/home', component: Home },
      {
        path: ':id', // 动态路径参数
        component: Detail
      }
    ]
  }

]

export default routes
