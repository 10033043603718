<script>
import { toThousands } from '@/utils'
import { getResultByMobile } from '@/api/home'
export default {
  name: 'detail-page',
  props: {},
  components: {},
  data () {
    return {
      toThousands,
      id: undefined,
      projectedRevenue: undefined
    }
  },
  computed: {},
  watch: {},
  methods: {
    init () {
      getResultByMobile({ id: this.id }).then(({ data }) => {
        this.projectedRevenue = data.projectedRevenue
      }).catch(error => error)
    }
  },
  created () {
    this.id = this.$route.params.id
    this.id && this.init()
  },
  mounted () {},
  beforeDestroy () {}
}
</script>
<template>
  <div class='detail flx-center'>
    <div class="earning">
      <span class="unit">￥ &nbsp;</span>
      <span>{{ toThousands(projectedRevenue || 0) }}</span>
    </div>
  </div>
</template>
<style scoped lang='less'>
.detail{
  margin: 0 auto;
  height: 100% !important;
  // max-width: 560px;
  min-height: 100vh;
  background: var(--background-color);
  background-image: url('@/assets/detail/bg@2x.png');
  background-size: 100% 100%;

  .earning{
    font-size: 32px;
    color: #FFDB54;
    transform: translate(0px, -25px);

    .unit{
      font-size: 16px;
    }
  }
}
</style>
